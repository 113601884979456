// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #60c9c9;
  margin: 0rem;
  font-family: "Roboto", "Helvetica", sans-serif, Arial;
}`, "",{"version":3,"sources":["webpack://./src/styles/partials/Global.scss","webpack://./src/App.scss"],"names":[],"mappings":"AAEA;EACI,yBAAA;EACA,YAAA;EACA,qDAAA;ACDJ","sourcesContent":["@use \"./Variables.scss\" as*;\n\nbody {\n    background-color: #60c9c9;\n    margin: 0rem;\n    font-family: 'Roboto','Helvetica', sans-serif, Arial;\n}","body {\n  background-color: #60c9c9;\n  margin: 0rem;\n  font-family: \"Roboto\", \"Helvetica\", sans-serif, Arial;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
